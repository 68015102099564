<template>
  <div>
    <div class="company-settings__table-wrapper">
      <div class="company-settings__table-search-wrapper">
        <telia-fieldset :legend="t('COMPANY_SETTINGS.ALL_ORGANISATIONS')">
          <telia-text-input
            t-id="search-field"
            class="company-settings__search-input"
            :disabled="fetchOrganisationsError"
            :label="t('COMPANY_SETTINGS.SEARCH_ORGANISATION')"
            @input="handleInputChange"
          >
            <div class="company-settings__search-icon-wrapper">
              <telia-icon v-show="showSearchIcon" name="search"></telia-icon>
            </div>
          </telia-text-input>
        </telia-fieldset>
      </div>
    </div>
    <b2x-table
      :columns="JSON.stringify(columns)"
      :data="JSON.stringify(displayedRows)"
      :is-loading="tableIsLoading"
      :message="tableMessage"
      @buttonTextClicked="onHandleConsentButtonClick"
    >
    </b2x-table>
    <b2x-paginator
      v-show="!fetchOrganisationsError"
      @paginationChange="onPaginationChange"
      :page-sizes="'[10, 25, 50, 100]'"
      :list-length="filteredRows.length"
    />
    <consent-drawer
      v-if="canReadConsent || canWriteConsent"
      v-show="isDrawerOpen"
      :isDrawerOpen="isDrawerOpen"
      :can-write-consent="canWriteConsent"
      :scopeId="scopeId"
      :tscid="selectedRowTscid"
      @closeDrawer="closeDrawer"
    ></consent-drawer>
  </div>
</template>

<script>
import { translateSetup, translateMixin } from "../locale";
import { logError } from "@telia/b2x-logging";
import { corpScopeInformation } from "@telia/b2b-rest-client";
import consentDrawer from "./consent-drawer.vue";
import { debounce } from "vue-debounce";

export default {
  name: "company-settings-table",
  mixins: [translateMixin],
  components: {
    consentDrawer,
  },
  props: {
    scopeId: {
      type: String,
      required: true,
    },
    canReadConsent: {
      type: Boolean,
      required: true,
    },
    canWriteConsent: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      organisationsData: null,
      isDrawerOpen: false,
      currentPage: 1,
      currentPageSize: 10,
      selectedRowTscid: "",
      tableIsLoading: true,
      searchValue: "",
      fetchOrganisationsError: false,
    };
  },
  async created() {
    await translateSetup(["mybusiness"]);
    this.searchOrganisationGA = debounce(this.searchOrganisationGA, 3000);
  },
  methods: {
    handleInputChange(event) {
      const input = event.target.value;
      this.searchValue = input;
    },
    onHandleConsentButtonClick(event) {
      const rowClicked = event.detail.rowNumber;
      this.selectedRowTscid = this.organisationsData[rowClicked].tscid;
      this.isDrawerOpen = true;
    },
    async fetchOrganisationsData() {
      this.tableIsLoading = true;
      this.fetchOrganisationsError = false;
      try {
        let response = await corpScopeInformation.ScopeControllerService.getOrganizationsForScope(
          this.scopeId,
          true
        );
        this.organisationsData = response;
      } catch (error) {
        this.fetchOrganisationsError = true;
        logError("b2b-company-settings", "Failed fetching organizations data");
      }
      this.tableIsLoading = false;
    },
    onScopeIdChange() {
      if (this.scopeId) {
        this.fetchOrganisationsData();
      }
    },
    closeDrawer() {
      this.isDrawerOpen = false;
    },
    onPaginationChange(event) {
      this.currentPage = event.detail.page;
      this.currentPageSize = event.detail.pageSize;
    },
  },
  computed: {
    showSearchIcon() {
      return this.searchValue === "";
    },
    columns() {
      return [
        { title: `${this.t("COMPANY_SETTINGS.TABLE.COMPANY_NAME")}`, type: "text" },
        { title: `${this.t("COMPANY_SETTINGS.TABLE.ORGANISATION_NUMBER")}`, type: "text" },
        (this.canReadConsent || this.canWriteConsent) && {
          title: `${this.t("COMPANY_SETTINGS.TABLE.CONSENT")}`,
          type: "button-text",
        },
      ].filter((column) => column);
    },
    allRows() {
      let rows = [];
      if (!this.organisationsData) {
        return rows;
      }

      this.organisationsData.forEach((organisationData, index) => {
        let row = [organisationData.name, organisationData.organizationNumber];

        if (this.canReadConsent || this.canWriteConsent) {
          row.push({
            content: `${this.t("COMPANY_SETTINGS.TABLE.MANAGE_CONSENT")}`,
            attrs: { variant: "expressive" },
            rowNumber: `${index}`,
          });
        }

        rows.push(row);
      });
      return rows;
    },
    filteredRows() {
      if (this.searchValue === "") {
        return this.allRows;
      }
      let filteredData = this.allRows.filter(
        (organisation) =>
          organisation[0].toLowerCase().includes(this.searchValue.toLowerCase()) ||
          organisation[1].toLowerCase().includes(this.searchValue.toLowerCase())
      );
      return filteredData;
    },
    displayedRows() {
      let startingRowIndex = (this.currentPage - 1) * this.currentPageSize;
      return this.filteredRows.slice(startingRowIndex, this.currentPageSize * this.currentPage);
    },
    tableMessage() {
      return this.fetchOrganisationsError
        ? this.t("COMPANY_SETTINGS.TABLE.FETCH_ORGANISATIONS_ERROR")
        : "";
    },
  },
  watch: {
    scopeId: {
      handler: "onScopeIdChange",
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@teliads/components/foundations/colors/mixins";
@import "@teliads/components/foundations/spacing/variables";

.company-settings {
  &__table-wrapper {
    margin-top: $telia-spacing-64;
  }

  &__table-search-wrapper {
    max-width: 42rem;
    margin-bottom: $telia-spacing-32;
  }

  &__search-input {
    position: relative;
  }

  &__search-icon-wrapper {
    position: absolute;
    right: 1rem;
    top: 3.5rem;
    pointer-events: none;
    @include telia-color("gray-500");
  }
}
</style>
