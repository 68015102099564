import { trackGa4, getGa4DefaultParams } from "@telia/b2b-analytics";
import type { TeliaEventName, TeliaEventParams } from "@telia/b2x-google-analytics";

type ServiceConfigStatus =
  | "service_config_initiate"
  | "service_config_progress"
  | "service_config_complete"
  | "service_config_fail";

type EventData = {
  logged_in: boolean;
  page_type: string;
  self_service_action_option: "telia_at_work";
  self_service_action: string;
  step_label: string;
  step_number?: number;
  error?: {
    error_type: string;
    error_message: string;
  };
};

const sharedValues = {
  ...getGa4DefaultParams(),
  logged_in: true,
  page_type: "settings",
};

export const trackAnalytics = (eventName: TeliaEventName, eventParams: TeliaEventParams) => {
  trackGa4(eventName, {
    ...sharedValues,
    ...eventParams,
  });
};

export const trackLinkInteraction = (
  click_action: string,
  click_text: string,
  click_type: string
) => {
  trackGa4("link_interaction", {
    ...sharedValues,
    click_action,
    click_text,
    click_type,
  });
};

export const trackServiceConfig = (
  status: ServiceConfigStatus,
  self_service_action: "activate" | "deactivate",
  step_label:
    | "org_selection"
    | "org_selection_confirm"
    | "org_selection_complete"
    | ServiceConfigStatus,
  error?: {
    error_type: "feedback_drawer" | "feedback_banner";
    error_message:
      | "In order to active, select org"
      | "In order to deactive, select org"
      | "Failed to deactivate organizations"
      | "Failed to activate organizations";
  }
) => {
  const step_number =
    status === "service_config_initiate" ? 1 : status === "service_config_progress" ? 2 : undefined;
  const eventData: EventData = {
    ...sharedValues,
    self_service_action_option: "telia_at_work",
    self_service_action,
    step_label,
    ...error,
  };

  if (step_number !== undefined) {
    eventData.step_number = step_number;
  }

  trackGa4(status, eventData);
};

export const trackAccordionInteraction = (click_text: string) => {
  trackGa4("accordion_interaction", {
    ...sharedValues,
    click_type: "accordion",
    click_text,
  });
};
