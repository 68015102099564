<template>
  <div class="description">
    <telia-p t-id="telia-at-work-drawer-description" variant="paragraph-200" disableHyphenation>
      {{ getDescription }}
    </telia-p>
    <telia-p
      v-if="inActiveOrgs.length"
      t-id="telia-at-work-drawer-deactivated_message"
      variant="paragraph-200"
      disableHyphenation
    >
      {{
        t("mybusiness.company_settings.telia_at_work.drawer.deactivate.deactivated.description", {
          orgCount: inActiveOrgs.length,
        })
      }}
    </telia-p>
  </div>
  <div class="button-group" :class="{ 'small-screen': isSmallScreen }">
    <telia-button
      v-if="orgCount > 0 && !successfullyUpdatedOrganizations"
      t-id="cancel-button"
      class="button"
      variant="secondary"
      type="button"
      :full-width="true"
      @click="handleCancelButtonClick"
    >
      {{ t("mybusiness.cancel") }}
    </telia-button>
    <b2x-loading-button
      v-if="orgCount > 0 && !successfullyUpdatedOrganizations"
      class="button"
      variant="destructive"
      :is-loading="submitting"
      :full-width="true"
      @click="submitDeactivateOrgs"
      >{{
        t("mybusiness.company_settings.telia_at_work.drawer.deactivate.button")
      }}</b2x-loading-button
    >
  </div>

  <div class="notification">
    <telia-notification
      v-if="showErrorNotification"
      status="error"
      :button-text="t('mybusiness.close')"
      heading-tag="h3"
      @vocaClose="() => (showErrorNotification = false)"
    >
      <span slot="heading">{{
        t("mybusiness.company_settings.telia_at_work.error_notification.header")
      }}</span>
      <telia-text-spacing slot="content">
        <telia-p>{{
          t("mybusiness.company_settings.telia_at_work.deactivate.error_notification.body")
        }}</telia-p>
      </telia-text-spacing>
    </telia-notification>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from "vue";

import { logError } from "@telia/b2x-logging";

import { useIsSmallScreen } from "./composables/small-screen";

import { translateMixin } from "../../locale";

import { SettingsDataResponse, setSettings } from "../../services/corpCompanyConfiguration";

import { trackServiceConfig } from "../../utils/trackGA4";

const props = defineProps<{
  activeOrgs: SettingsDataResponse;
  inActiveOrgs: SettingsDataResponse;
  scopeId: string;
}>();

const emit = defineEmits<{
  (e: "successfullyUpdatedOrganizations"): void;
  (e: "closeDrawer"): void;
}>();

const t = translateMixin.methods.t;
const submitting = ref(false);
const showErrorNotification = ref(false);
const successfullyUpdatedOrganizations = ref(false);
const { isSmallScreen } = useIsSmallScreen();

onMounted(() => {
  successfullyUpdatedOrganizations.value = false;
  showErrorNotification.value = false;
  if (props.activeOrgs.length === 0) {
    trackServiceConfig("service_config_fail", "deactivate", "service_config_initiate", {
      error_message: "In order to deactive, select org",
      error_type: "feedback_drawer",
    });
  }
});

const getDescription = computed(() => {
  if (successfullyUpdatedOrganizations.value) {
    return t(
      "mybusiness.company_settings.telia_at_work.drawer.deactivate.succesful_deactivation_description"
    );
  }

  return orgCount.value === 0
    ? t("mybusiness.company_settings.telia_at_work.drawer.deactivate.no_organizations_selected")
    : t("mybusiness.company_settings.telia_at_work.drawer.deactivate.description", {
        orgCount: orgCount.value,
      });
});

const orgCount = computed(() => {
  return props.activeOrgs.length;
});

const submitDeactivateOrgs = async () => {
  try {
    showErrorNotification.value = false;
    submitting.value = true;
    const tscids = props.activeOrgs.map((org) => org.tscid);
    const body = {
      tscids,
      allowed: false,
    };

    trackServiceConfig("service_config_progress", "deactivate", "org_selection_confirm");

    await setSettings(props.scopeId, body);

    successfullyUpdatedOrganizations.value = true;
    trackServiceConfig("service_config_complete", "deactivate", "org_selection_complete");
    emit("successfullyUpdatedOrganizations");
  } catch (error) {
    showErrorNotification.value = true;
    trackServiceConfig("service_config_fail", "deactivate", "org_selection_complete", {
      error_type: "feedback_drawer",
      error_message: "Failed to deactivate organizations",
    });
    logError("b2b-company-settings", "Failed to call corpCompanyConfiguration setSettings");
  } finally {
    submitting.value = false;
  }
};

const handleCancelButtonClick = () => {
  emit("closeDrawer");
};
</script>

<style lang="scss" scoped>
.description {
  margin-bottom: var(--purpur-spacing-400);
}

.notification {
  margin-top: var(--purpur-spacing-400);
}

.button-group {
  display: flex;
  flex-direction: row;
  gap: var(--purpur-spacing-200);
  align-self: stretch;

  &.small-screen {
    flex-direction: column-reverse;
  }

  .button {
    gap: var(--purpur-spacing-100);
    flex: 1 0 0;
  }
}
</style>
