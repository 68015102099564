<template>
  <b2x-drawer
    drawer-id="benefit-drawer-add"
    position="right"
    :heading="t('COMPANY_SETTINGS.E_SIM_WATCH.DRAWER_TITLE')"
    :is-open="isDrawerOpen"
    @drawerClose="closeDrawer()"
  >
    <telia-divider></telia-divider>
    <telia-p class="e-sim-watch-drawer__body-text" disable-hyphenation>
      {{ t("COMPANY_SETTINGS.E_SIM_WATCH.DRAWER_BODY") }}
    </telia-p>
    <telia-form @submit.prevent="submit">
      <telia-fieldset>
        <telia-radio-button
          :checked="selectedPolicyType === 'MANAGER_ONLY'"
          :label="t('COMPANY_SETTINGS.E_SIM_WATCH.MANAGER_ONLY')"
          :disabled="!allowedPolicies.managerOnly"
          name="purchaseSettings"
          @change="radioButtonChange('MANAGER_ONLY')"
        >
        </telia-radio-button>
        <telia-radio-button
          t-id="e-sim-watch-radio-button-2"
          :checked="selectedPolicyType === 'ALLOW_ALL'"
          :label="t('COMPANY_SETTINGS.E_SIM_WATCH.ALLOW_ALL')"
          :disabled="!allowedPolicies.allowAll"
          name="purchaseSettings"
          @change="radioButtonChange('ALLOW_ALL')"
        >
        </telia-radio-button>
        <telia-radio-button
          :checked="selectedPolicyType === 'PRIVATE_ONLY'"
          :label="t('COMPANY_SETTINGS.E_SIM_WATCH.PRIVATE_ONLY')"
          :disabled="!allowedPolicies.privateOnly"
          name="purchaseSettings"
          @change="radioButtonChange('PRIVATE_ONLY')"
        >
        </telia-radio-button>
      </telia-fieldset>
      <div class="my-profile__button-wrapper">
        <telia-button
          t-id="e-sim-watch-submit-button"
          :text="t('COMPANY_SETTINGS.E_SIM_WATCH.SAVE')"
          variant="primary"
          class="my-profile__form-button"
        />
      </div>
    </telia-form>
    <telia-notification
      v-show="submitError"
      t-id="submit-error-notification"
      status="warning"
      heading-tag="h2"
    >
      <telia-p slot="heading" disable-hyphenation>
        {{ t(`COMPANY_SETTINGS.E_SIM_WATCH.${submitErrorKey}`) }}
      </telia-p>
    </telia-notification>
  </b2x-drawer>
</template>

<script>
import { translateSetup, translateMixin } from "../locale";
import CompanySettingsStateService from "../services/company-settings.state.service";
import { corpMdsPolicy } from "@telia/b2b-rest-client";
import swedishTranslations from "../locale/sv.json";

export default {
  name: "e-sim-watch-drawer",
  mixins: [translateMixin],
  components: {},
  props: {
    isDrawerOpen: {
      type: Boolean,
      required: true,
    },
    agreement: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    companySettingsStateService: undefined,
    userName: undefined,
    purposes: [],
    purposesBackup: [],
    loadingPurposes: true,
    selectedPolicyType: undefined,
    allowedPolicies: {
      allowAll: false,
      managerOnly: false,
      privateOnly: false,
    },
    submitError: false,
    submitErrorKey: "SUBMIT_ERROR",
  }),
  async created() {
    await translateSetup(["mybusiness"]);
    this.companySettingsStateService = await CompanySettingsStateService.getInstance();
  },
  methods: {
    onDrawerIsOpenChange() {
      if (this.isDrawerOpen) {
        this.selectedPolicyType = this.agreement.policyType;
        this.allowedPolicies = this.agreement.allowedPolicies;
      }
    },
    closeDrawer() {
      this.selectedAgreement = undefined;
      this.submitError = false;
      this.$emit("closeDrawer");
    },
    radioButtonChange(selected) {
      this.selectedPolicyType = selected;
    },
    async submit() {
      this.submitError = false;

      // Close drawer without submitting if value has not been changed
      if (this.selectedPolicyType === this.agreement.policyType) {
        this.$emit("closeDrawer");
        return;
      }

      let scopeId = this.companySettingsStateService.state.loggedInUser.scopeId;
      let tscid = this.agreement.tscid;

      let mdsPolicyDto = {};
      mdsPolicyDto.agreementNumber = this.agreement.agreementNumber;
      mdsPolicyDto.policyType = this.selectedPolicyType;

      try {
        await corpMdsPolicy.MdsPolicyControllerService.setPolicy(scopeId, tscid, mdsPolicyDto);
        this.$emit("agreementUpdated", this.agreement.agreementNumber, this.selectedPolicyType);
        this.$emit("closeDrawer");
      } catch (error) {
        if (error?.body?.translationKey === "NO_AGREEMENTS_FOUND") {
          this.submitErrorKey = "NO_AGREEMENTS_FOUND";
        } else {
          this.submitErrorKey = "SUBMIT_ERROR";
        }

        this.submitError = true;
      }
    },
  },
  watch: {
    isDrawerOpen: { handler: "onDrawerIsOpenChange" },
  },
};
</script>

<style scoped lang="scss">
@import "@teliads/components/foundations/spacing/variables";

.e-sim-watch-drawer {
  &__body-text {
    margin-top: $telia-spacing-16;
  }
}
</style>
