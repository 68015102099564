<template>
  <div class="company-settings">
    <div class="company-settings__header">
      <telia-grid class="company-settings__header-grid">
        <telia-heading t-id="heading" v-if="companyName" variant="title-300" tag="h1">
          {{ t("COMPANY_SETTINGS.HEADER_TITLE", { companyName }) }}
        </telia-heading>
        <telia-skeleton v-else class="company-settings__header-title-skeleton" />
      </telia-grid>
    </div>

    <telia-grid class="company-settings__body-grid">
      <div v-if="loadingAccordions" class="company-settings__accordion-skeleton">
        <telia-skeleton class="company-settings__accordion-heading-skeleton" />
        <telia-divider variant="strong" />
        <telia-skeleton class="company-settings__accordion-item-skeleton" />
        <telia-divider variant="strong" />
        <telia-skeleton class="company-settings__accordion-item-skeleton" />
        <telia-divider variant="strong" />
      </div>
      <telia-accordion v-if="showAccordions" indented :heading="t('COMPANY_SETTINGS.BODY_TITLE')">
        <telia-accordion-item
          v-if="hasESimWatchAction"
          :heading="t('COMPANY_SETTINGS.E_SIM')"
          t-id="e-sim-watch-accordion"
          @click="openESimWatchAccordion"
        >
          <e-sim-watch
            v-if="loadAccordion && loadAccordion.eSimWatch && showESimWatchContent"
          ></e-sim-watch>
        </telia-accordion-item>

        <telia-accordion-item
          :heading="t('COMPANY_SETTINGS.DATANET')"
          t-id="datanet-shareorders-accordion"
          v-if="hasDatanetSharedOrdersAction"
          @click="openDatanetSharedOrdersAccordion"
        >
          <datanet-shareorders
            v-if="loadAccordion && loadAccordion.dataNet && showDatanetSharedOrdersContent"
          ></datanet-shareorders>
        </telia-accordion-item>

        <telia-accordion-item
          v-if="hasEditInvoiceEmailsAction"
          :heading="t('COMPANY_SETTINGS.INVOICE_EMAIL.HEADING')"
          t-id="invoice-email-accordion"
          @click="openEditInvoiceEmailsAccordion"
        >
          <invoice-email
            v-if="loadAccordion && loadAccordion.invoiceEmail && showEditInvoiceEmailsContent"
          />
        </telia-accordion-item>

        <telia-accordion-item
          v-if="featureToggleTeliaAtWork && hasTeliaAtWorkAction"
          :heading="t('mybusiness.company_settings.telia_at_work.heading')"
          t-id="telia-at-work-accordion"
          @click="openTeliaAtWorkAccordion"
        >
          <TeliaAtWork
            v-if="loadAccordion && loadAccordion.teliaAtWork && showTeliaAtWorkContent"
          />
        </telia-accordion-item>
      </telia-accordion>
      <company-settings-table
        :scope-id="scopeId"
        :can-read-consent="canReadConsent"
        :can-write-consent="canWriteConsent"
      />
    </telia-grid>
  </div>
</template>

<script>
import { logError } from "@telia/b2x-logging";
import { useFlag } from "@unleash/proxy-client-vue";
import { getScopeId } from "@telia/b2b-customer-scope";
import { corpScopeInformation } from "@telia/b2b-rest-client";

import { translateSetup, translateMixin } from "./locale";

import CompanySettingsStateService from "./services/company-settings.state.service";

import { trackAccordionInteraction } from "./utils/trackGA4";

import eSimWatch from "./components/e-sim-watch.vue";
import datanetShareorders from "./components/datanet-shareorders.vue";
import TeliaAtWork from "./components/telia-at-work/telia-at-work.vue";
import invoiceEmail from "./components/invoice-email/invoice-email.vue";
import companySettingsTable from "./components/company-settings-table.vue";

export default {
  name: "b2b-company-settings",
  mixins: [translateMixin],
  components: {
    companySettingsTable,
    datanetShareorders,
    invoiceEmail,
    eSimWatch,
    TeliaAtWork,
  },
  data() {
    return {
      companyName: null,
      scopeId: "",
      hasDatanetSharedOrdersAction: null,
      hasESimWatchAction: null,
      hasTeliaAtWorkAction: null,
      hasEditInvoiceEmailsAction: false,
      showDatanetSharedOrdersContent: false,
      showESimWatchContent: false,
      showTeliaAtWorkContent: false,
      showEditInvoiceEmailsContent: false,
      canReadConsent: false,
      canWriteConsent: false,
      eSimWatchShouldFetchContent: false,
      companySettingsStateService: null,
      loadingAccordions: true,
      featureToggleTeliaAtWork: false,
    };
  },
  async created() {
    this.companySettingsStateService = await CompanySettingsStateService.getInstance();
    await translateSetup(["mybusiness"]);
    document.title = this.t("COMPANY_SETTINGS.DOCUMENT_TITLE");
    this.scopeId = await getScopeId();
    this.fetchOrganisation();
  },
  beforeMount() {
    this.featureToggleTeliaAtWork = useFlag("b2b-company-settings-telia-at-work");
  },
  methods: {
    async fetchOrganisation() {
      try {
        let scopeDetails = await corpScopeInformation.ScopeControllerService.getScopeDetails(
          this.scopeId
        );

        const { actions } = scopeDetails.scope;

        this.companyName = scopeDetails.scope.name;
        this.hasDatanetSharedOrdersAction = this.isIncludedInActions(
          actions,
          "MANAGE_DATANET_SHARED_ORDERS"
        );
        this.hasESimWatchAction = this.isIncludedInActions(actions, "MANAGE_MDS_POLICY");
        this.hasTeliaAtWorkAction = this.isIncludedInActions(
          actions,
          "READ_TELIA_AT_WORK_ORGANIZATION_SETTING"
        );
        this.canReadConsent = this.isIncludedInActions(actions, "READ_GDPR_PERMISSIONS");
        this.canWriteConsent = this.isIncludedInActions(actions, "WRITE_GDPR_PERMISSIONS");
        this.hasEditInvoiceEmailsAction = this.isIncludedInActions(
          actions,
          "UPDATE_INVOICE_ACCOUNT"
        );
      } catch (error) {
        logError("b2b-company-settings", "Failed fetching organization");
      } finally {
        this.loadingAccordions = false;
      }
    },
    isIncludedInActions(actions, pui) {
      return actions.available.indexOf(pui) > -1;
    },
    openAccordion(accordionName) {
      this.companySettingsStateService.loadAccordionContent(accordionName);
    },
    openTeliaAtWorkAccordion(event) {
      if (event.srcElement.tagName !== "TELIA-ACCORDION-ITEM") return;
      this.showTeliaAtWorkContent = !this.showTeliaAtWorkContent;
      if (this.showTeliaAtWorkContent) {
        trackAccordionInteraction("Telia at Work");
      }
      this.openAccordion("teliaAtWork");
    },
    openDatanetSharedOrdersAccordion(event) {
      if (event.srcElement.tagName !== "TELIA-ACCORDION-ITEM") return;
      this.showDatanetSharedOrdersContent = !this.showDatanetSharedOrdersContent;
      this.openAccordion("dataNet");
    },
    openESimWatchAccordion(event) {
      if (event.srcElement.tagName !== "TELIA-ACCORDION-ITEM") return;
      this.showESimWatchContent = !this.showESimWatchContent;
      this.openAccordion("eSimWatch");
    },
    openEditInvoiceEmailsAccordion(event) {
      if (event.srcElement.tagName !== "TELIA-ACCORDION-ITEM") return;
      this.showEditInvoiceEmailsContent = !this.showEditInvoiceEmailsContent;
      this.openAccordion("invoiceEmail");
    },
  },
  computed: {
    loadAccordion() {
      return this.companySettingsStateService?.state.layout.accordionLoaded;
    },
    showAccordions() {
      return (
        !this.loadingAccordions &&
        (this.hasESimWatchAction ||
          this.hasDatanetSharedOrdersAction ||
          this.hasEditInvoiceEmailsAction ||
          this.hasTeliaAtWorkAction)
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@teliads/components/foundations/colors/mixins";
@import "@teliads/components/foundations/spacing/variables";
@import "@teliads/components/foundations/breakpoints/mixins";

telia-accordion-item {
  --vds-accordion-item-content-max-width: 100%;
}

.company-settings {
  &__toast {
    position: fixed;
    right: $telia-spacing-12;
    bottom: $telia-spacing-32;
  }

  &__header {
    padding: $telia-spacing-64 0;
    @include telia-bg-color("gray-50");
  }

  &__header-grid {
    justify-content: space-between;

    @include telia-breakpoint("lg") {
      display: flex;
    }
  }

  &__header-title-skeleton {
    width: 60rem;
    height: 4rem;
  }

  &__accordion-heading-skeleton {
    width: 52rem;
    height: 3.7rem;
    margin: 3.3rem 0;
  }

  &__accordion-item-skeleton {
    width: 25rem;
    height: 2.3rem;
    margin: 3.3rem 0;
  }

  &__body-grid {
    padding-bottom: $telia-spacing-64;
  }

  &__link-icon {
    vertical-align: middle;
    margin-right: $telia-spacing-8;
  }
}
</style>
