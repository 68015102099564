import { IOrganisation, ICompanySettingsState } from "./interfaces/ICompanySettingsState";
import { getOrganizations } from "@telia/b2b-logged-in-service";
import { getScopeIdOrThrow } from "@telia/b2b-customer-scope";

export default class CompanySettingsStateService {
  public state: ICompanySettingsState;
  private static instance: CompanySettingsStateService;

  constructor() {
    this.state = this._createState();
    this._initializeData();
  }

  public static getInstance(): CompanySettingsStateService {
    if (!this.instance) {
      this.instance = new CompanySettingsStateService();
    }
    return this.instance;
  }

  public loadAccordionContent(accordionName: string): void {
    this.state.layout.accordionLoaded[accordionName] = true;
  }

  private getAccordionGALabel(accordionName: string): string {
    switch (accordionName) {
      case "eSimWatch":
        return "E-sim";
      case "dataNet":
        return "DataNet";
      case "invoiceEmail":
        return "Invoice_Email";
      default:
        return "";
    }
  }

  private _createState(): ICompanySettingsState {
    return {
      organisations: {
        list: [
          {
            tscid: "",
            name: "",
            organizationNumber: "",
          },
        ],
        tscidList: [],
        error: false,
      },
      loggedInUser: { scopeId: "" },
      layout: {
        loading: false,
        accordionLoaded: {
          eSimWatch: false,
          dataNet: false,
          invoiceEmail: false,
          teliaAtWork: false,
        },
      },
    };
  }

  private async _initializeData(): Promise<void> {
    this.state.loggedInUser.scopeId = await getScopeIdOrThrow();
    await this._setOrganisations();
  }

  private async _setOrganisations(): Promise<void> {
    let organisations;
    try {
      organisations = await getOrganizations();

      organisations.sort((a: IOrganisation, b: IOrganisation) => {
        const nameOne = a.name.toUpperCase();
        const nameTwo = b.name.toUpperCase();
        if (nameOne < nameTwo) {
          return -1;
        }
        if (nameOne > nameTwo) {
          return 1;
        }
        return 0;
      });

      organisations.forEach((org: IOrganisation) => {
        this.state.organisations.tscidList.push(org.tscid);
        this.state.organisations.list = organisations;
      });
    } catch (error) {
      this.state.organisations.error = true;
    }
  }
}
