import { corpCompanyConfiguration } from "@telia/b2b-rest-client";

export type SettingsDataRow = {
  organizationName: string;
  organizationNumber: string;
  tscid: string;
  active: boolean;
};
export type SettingsDataResponse = SettingsDataRow[];

export const getSettings = async (scopeId: string): Promise<SettingsDataResponse> => {
  const data = await corpCompanyConfiguration.TawControllerService.getSettings(scopeId);

  const mappedData: SettingsDataResponse = data.map((dataRow) => ({
    organizationNumber: dataRow.organisationNumber!,
    tscid: dataRow.tscid!,
    active: dataRow.active!,
    organizationName: dataRow.organisationName!,
  }));

  return mappedData;
};

export const setSettings = async (
  scopeId: string,
  body: { tscids: string[]; allowed: boolean }
): Promise<void> => {
  return await corpCompanyConfiguration.TawControllerService.setSettings(scopeId, body);
};
