import { ref, onMounted, onUnmounted } from "vue";

const isSmallScreen = ref(window.innerWidth <= 768);
let listenerCount = 0;

const updateScreenSize = () => {
  isSmallScreen.value = window.innerWidth <= 768;
};

export const useIsSmallScreen = () => {
  onMounted(() => {
    if (listenerCount === 0) {
      window.addEventListener("resize", updateScreenSize);
    }
    listenerCount++;
  });

  onUnmounted(() => {
    listenerCount--;
    if (listenerCount === 0) {
      window.removeEventListener("resize", updateScreenSize);
    }
  });

  return { isSmallScreen };
};
