<template>
  <telia-link
    t-id="telia-at-work-download-pdf"
    @click="handleButtonClick"
    variant="standalone"
    href="/assets/m/1d8770d5eb00ffb5/original/Telia-at-Work-How-to.pdf"
    target="_blank"
  >
    {{ linkText }}
    <telia-icon name="external" size="sm" />
  </telia-link>
</template>

<script setup lang="ts">
import { translateMixin } from "../../locale";

import { trackAnalytics } from "../../utils/trackGA4";

const t = translateMixin.methods.t;

const linkText = t("mybusiness.company_settings.telia_at_work.links.pdf");

const handleButtonClick = () => {
  trackAnalytics("download", {
    click_type: "download",
    click_text: linkText,
  });
};
</script>

<style lang="scss" scoped>
telia-link {
  telia-icon {
    margin-left: var(--purpur-spacing-100);
    margin-top: -0.5rem;
    vertical-align: middle;
  }
}
</style>
