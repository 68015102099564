<template>
  <telia-p
    t-id="telia-at-work-drawer-description"
    class="activation-description"
    variant="paragraph-200"
    disableHyphenation
  >
    {{ getDescription }}
  </telia-p>
  <template v-if="inActiveOrgsCount > 0 && !successfullyUpdatedOrganizations">
    <div class="activation-message">
      <telia-p t-id="telia-at-work-drawer-activation_message" variant="paragraph-200">
        {{
          t("mybusiness.company_settings.telia_at_work.drawer.activate.activation_message", {
            orgCount: inActiveOrgsCount,
          })
        }}
      </telia-p>
      <telia-p
        v-if="activeOrgs.length"
        t-id="telia-at-work-drawer-activated-orgs_message"
        variant="paragraph-200"
      >
        {{
          t("mybusiness.company_settings.telia_at_work.drawer.activate.already_activated_message", {
            orgCount: activeOrgs.length,
          })
        }}
      </telia-p>
    </div>
    <div t-id="telia-at-work-terms" class="terms-of-service">
      <telia-text-spacing>
        <telia-heading tag="h3" variant="title-100">
          {{ t("mybusiness.company_settings.telia_at_work.drawer.activate.terms.header") }}
        </telia-heading>
        <telia-p variant="paragraph-100" disableHyphenation>{{
          t(
            "mybusiness.company_settings.telia_at_work.drawer.activate.terms_of_service.paragraph_1"
          )
        }}</telia-p>
        <telia-p variant="paragraph-100" disableHyphenation>{{
          t(
            "mybusiness.company_settings.telia_at_work.drawer.activate.terms_of_service.paragraph_2"
          )
        }}</telia-p>
      </telia-text-spacing>
    </div>
    <telia-form @submit.prevent="submitActivateForm">
      <telia-checkbox
        class="terms-checkbox"
        required
        :requiredErrorMessage="
          t(
            'mybusiness.company_settings.telia_at_work.drawer.activate.terms.checkbox_required_message'
          )
        "
        >{{
          t("mybusiness.company_settings.telia_at_work.drawer.activate.terms.checkbox")
        }}</telia-checkbox
      >
      <div class="form-buttons" :class="{ 'small-screen': isSmallScreen }">
        <telia-button
          t-id="cancel-button"
          class="form-button"
          variant="secondary"
          type="button"
          :full-width="true"
          @click="handleCancelButtonClick"
        >
          {{ t("mybusiness.cancel") }}
        </telia-button>
        <b2x-loading-button
          class="form-button"
          variant="primary"
          type="submit"
          :is-loading="submitting"
          :full-width="true"
          >{{
            t("mybusiness.company_settings.telia_at_work.drawer.activate.button")
          }}</b2x-loading-button
        >
      </div>
    </telia-form>
    <telia-notification
      v-if="showErrorNotification"
      status="error"
      :button-text="t('mybusiness.close')"
      heading-tag="h3"
      @vocaClose="() => (showErrorNotification = false)"
    >
      <span slot="heading">{{
        t("mybusiness.company_settings.telia_at_work.error_notification.header")
      }}</span>
      <telia-text-spacing slot="content">
        <telia-p>{{
          t("mybusiness.company_settings.telia_at_work.activate.error_notification.body")
        }}</telia-p>
      </telia-text-spacing>
    </telia-notification>
  </template>
  <template v-if="inActiveOrgsCount === 0">
    <telia-p
      v-if="activeOrgs.length"
      t-id="telia-at-work-drawer-activated-orgs_message"
      variant="paragraph-200"
    >
      {{
        t("mybusiness.company_settings.telia_at_work.drawer.activate.already_activated_message", {
          orgCount: activeOrgs.length,
        })
      }}
    </telia-p>
  </template>
  <template v-if="successfullyUpdatedOrganizations">
    <DownloadPdfLink />
  </template>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from "vue";

import { logError } from "@telia/b2x-logging";

import { useIsSmallScreen } from "./composables/small-screen";

import { translateMixin } from "../../locale";

import DownloadPdfLink from "./download-pdf-link.vue";

import { SettingsDataResponse, setSettings } from "../../services/corpCompanyConfiguration";

import { trackServiceConfig } from "../../utils/trackGA4";

const props = defineProps<{
  activeOrgs: SettingsDataResponse;
  inActiveOrgs: SettingsDataResponse;
  scopeId: string;
}>();

const emit = defineEmits<{
  (e: "successfullyUpdatedOrganizations"): void;
  (e: "closeDrawer"): void;
}>();

const t = translateMixin.methods.t;
const showErrorNotification = ref(false);
const submitting = ref(false);
const successfullyUpdatedOrganizations = ref(false);
const { isSmallScreen } = useIsSmallScreen();

onMounted(() => {
  submitting.value = false;
  successfullyUpdatedOrganizations.value = false;
  if (inActiveOrgsCount.value === 0) {
    trackServiceConfig("service_config_fail", "activate", "service_config_initiate", {
      error_message: "In order to active, select org",
      error_type: "feedback_drawer",
    });
  }
});

const getDescription = computed(() => {
  if (successfullyUpdatedOrganizations.value) {
    return t(
      "mybusiness.company_settings.telia_at_work.drawer.activate.succesful_activation_description"
    );
  }

  return inActiveOrgsCount.value === 0
    ? t("mybusiness.company_settings.telia_at_work.drawer.activate.no_organizations_selected")
    : t("mybusiness.company_settings.telia_at_work.drawer.activate.description", {
        orgCount: inActiveOrgsCount.value,
      });
});

const inActiveOrgsCount = computed(() => {
  return props.inActiveOrgs.length;
});

const submitActivateForm = async () => {
  try {
    showErrorNotification.value = false;
    submitting.value = true;
    const tscids = props.inActiveOrgs.map((org) => org.tscid);
    const body = {
      tscids,
      allowed: true,
    };
    trackServiceConfig("service_config_progress", "activate", "org_selection_confirm");

    await setSettings(props.scopeId, body);

    successfullyUpdatedOrganizations.value = true;
    trackServiceConfig("service_config_complete", "activate", "org_selection_complete");
    emit("successfullyUpdatedOrganizations");
  } catch (error) {
    showErrorNotification.value = true;
    logError("b2b-company-settings", "Failed to call corpCompanyConfiguration setSettings");
    trackServiceConfig("service_config_fail", "activate", "service_config_complete", {
      error_type: "feedback_drawer",
      error_message: "Failed to activate organizations",
    });
  } finally {
    submitting.value = false;
  }
};

const handleCancelButtonClick = () => {
  emit("closeDrawer");
};
</script>

<style lang="scss" scoped>
.activation-description {
  margin-bottom: var(--purpur-spacing-400);
}

.activation-message {
  margin-bottom: var(--purpur-spacing-400);
}

.terms-of-service {
  margin-bottom: var(--purpur-spacing-200);
}

.terms-checkbox {
  margin-bottom: var(--purpur-spacing-400);
}

telia-form {
  display: flex;
  flex-direction: column;
  align-self: stretch;
}

.form-buttons {
  display: flex;
  flex-direction: row;
  gap: var(--purpur-spacing-200);
  align-self: stretch;

  &.small-screen {
    flex-direction: column-reverse;
  }

  .form-button {
    gap: var(--purpur-spacing-100);
    flex: 1 0 0;
  }
}
</style>
