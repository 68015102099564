<template>
  <b2x-drawer
    position="right"
    :heading="t('COMPANY_SETTINGS.DRAWER.TITLE')"
    :is-open="isDrawerOpen"
    @drawerClose="closeDrawer()"
  >
    <telia-divider></telia-divider>
    <div v-show="!loadingPurposes">
      <div class="consent-drawer__paragraph-wrapper">
        <telia-p v-show="lastEdit.time && lastEdit.name" class="consent-drawer__drawer-paragraph">
          <strong t-id="last-edit-text">{{ latestUpdateText }}</strong>
        </telia-p>
      </div>
      <telia-p disable-hyphenation class="consent-drawer__drawer-paragraph">
        {{ t("COMPANY_SETTINGS.DRAWER.INFO_TEXT") }}
      </telia-p>
      <div class="consent-drawer__paragraph-wrapper">
        <telia-p disable-hyphenation class="consent-drawer__drawer-paragraph">
          {{ t("COMPANY_SETTINGS.DRAWER.READ_MORE_PART_1") }}
          <b2x-sspa-link href="/foretag/om/integritetspolicy">
            {{ t("COMPANY_SETTINGS.DRAWER.READ_MORE_LINK_TEXT") }}
          </b2x-sspa-link>
          {{ t("COMPANY_SETTINGS.DRAWER.READ_MORE_PART_2") }}
        </telia-p>
      </div>
      <div v-show="purposes">
        <div v-for="(purpose, index) in purposes" :key="purpose.id">
          <telia-toggle
            :id="index"
            class="consent-drawer__purpose-toggle"
            :label="getPurposeLabel(purpose)"
            label-position="after"
            :selected="getConsentStatusBoolean(purpose.status)"
            :disabled="!canWriteConsent"
            @vocaChange="onConsentToggleChange"
          ></telia-toggle>
        </div>
      </div>
    </div>
    <div v-show="loadingPurposes">
      <telia-skeleton class="consent-drawer__latest-update-skeleton"></telia-skeleton>
      <telia-skeleton class="consent-drawer__info-text-skeleton"></telia-skeleton>
      <telia-skeleton class="consent-drawer__read-more-skeleton"></telia-skeleton>
      <div class="consent-drawer__purpose-skeleton">
        <telia-skeleton class="consent-drawer__toggle-skeleton"></telia-skeleton>
        <telia-skeleton class="consent-drawer__description-skeleton"></telia-skeleton>
      </div>
      <div class="consent-drawer__purpose-skeleton">
        <telia-skeleton class="consent-drawer__toggle-skeleton"></telia-skeleton>
        <telia-skeleton class="consent-drawer__description-skeleton"></telia-skeleton>
      </div>
    </div>
    <telia-notification v-show="fetchConsentDataError" status="information" heading-tag="h3">
      <telia-p slot="heading">
        {{ t("COMPANY_SETTINGS.DRAWER.FETCH_CONSENT_ERROR") }}
      </telia-p>
    </telia-notification>
    <telia-notification v-show="saveConsentDataError" status="information" heading-tag="h3">
      <telia-p slot="heading">
        {{ t("COMPANY_SETTINGS.DRAWER.SAVE_CONSENT_ERROR") }}
      </telia-p>
    </telia-notification>
  </b2x-drawer>
</template>
<script>
import { translateSetup, translateMixin } from "../locale";
import { logError } from "@telia/b2x-logging";
import { corpConsent } from "@telia/b2b-rest-client";
import { currentLanguage } from "@telia/b2b-i18n";
import { getLoggedInUserInfo } from "@telia/b2b-logged-in-service";
import { debounce } from "vue-debounce";
import _ from "lodash";

export default {
  name: "consent-drawer",
  mixins: [translateMixin],
  components: {},
  props: {
    canWriteConsent: {
      type: Boolean,
      required: true,
    },
    isDrawerOpen: {
      type: Boolean,
      required: true,
    },
    tscid: {
      type: String,
      required: true,
    },
    scopeId: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    userName: undefined,
    purposes: [],
    purposesBackup: [],
    loadingPurposes: true,
    fetchConsentDataError: false,
    saveConsentDataError: false,
  }),
  async created() {
    await translateSetup(["mybusiness"]);
    await this.fetchUserName();
    this.saveConsentData = debounce(this.saveConsentData, 1000);
  },
  methods: {
    closeDrawer() {
      this.$emit("closeDrawer");
    },
    async fetchConsentData() {
      this.fetchConsentDataError = false;
      this.loadingPurposes = true;
      this.purposes = [];
      this.purposesBackup = [];

      try {
        let response = await corpConsent.CorpConsentService.getConsent(this.scopeId, this.tscid);
        this.purposes = response;
        this.purposesBackup = _.cloneDeep(response);
      } catch (error) {
        this.fetchConsentDataError = true;
        logError("b2b-company-settings", "Failed fetching consent data");
      }
      this.loadingPurposes = false;
    },
    async onIsDrawerOpenChange() {
      if (this.isDrawerOpen) {
        await this.fetchConsentData();
      } else {
        this.saveConsentDataError = false;
      }
    },
    getConsentStatusBoolean(status) {
      switch (status) {
        case "approved":
          return true;
        default:
          return false;
      }
    },
    async fetchUserName() {
      try {
        let userInfo = await getLoggedInUserInfo();
        this.userName = `${userInfo.firstName} ${userInfo.lastName}`;
      } catch (error) {
        logError("b2b-company-settings", "Failed fetching user name");
      }
    },
    async saveConsentData() {
      if (!this.canWriteConsent) {
        return;
      }

      this.saveConsentDataError = false;
      this.updatePurposesArray();

      corpConsent.CorpConsentService.setCorporateConsent(
        this.scopeId,
        this.tscid,
        this.consentDataToSave
      )
        .then(() => {
          this.purposesBackup = _.cloneDeep(this.purposes);
        })
        .catch(() => {
          this.saveConsentDataError = true;
          this.purposes = _.cloneDeep(this.purposesBackup);
          logError("b2b-company-settings", "Failed saving consent data");
        });
    },
    getPurposeLabel(purpose) {
      if (currentLanguage() === "en") {
        return purpose.description.en;
      }
      return purpose.description.sv;
    },
    onConsentToggleChange(event) {
      let index = event.srcElement.id;
      let newStatus = event.detail.value ? "approved" : "denied";

      this.purposes[index].status = newStatus;
      this.saveConsentData();
    },
    updatePurposesArray() {
      const date = new Date();
      let updatedPurposes = this.purposes.map((purpose) => {
        purpose.lastUpdated = date.toISOString();
        purpose.customerProxy = this.userName;
        if (purpose.status === "not_answered") {
          purpose.status = "denied";
        }
        return purpose;
      });
      this.purposes = updatedPurposes;
    },
  },
  computed: {
    lastEdit() {
      let lastEditedTime = null;
      let lastEditedName = "";
      for (let purpose of this.purposes) {
        if (!lastEditedTime || purpose.lastUpdated > lastEditedTime) {
          lastEditedTime = purpose.lastUpdated;
          lastEditedName = purpose.customerProxy;
        }
      }
      return {
        time: lastEditedTime,
        name: lastEditedName,
      };
    },
    latestUpdateText() {
      if (this.lastEdit.time && this.lastEdit.name) {
        let lastEditedDateObject = new Date(this.lastEdit.time);

        const lastEditedTime = new Intl.DateTimeFormat(currentLanguage(), {
          dateStyle: "short",
          timeStyle: "short",
        }).format(lastEditedDateObject);

        return this.t("COMPANY_SETTINGS.DRAWER.LATEST_UPDATE", {
          lastEditedTime,
          lastEditedName: this.lastEdit.name,
        });
      } else {
        return "";
      }
    },
    consentDataToSave() {
      let purposesToSave = this.purposes.map((purpose) => {
        return {
          id: purpose.id,
          status: purpose.status,
          version: purpose.version,
        };
      });

      return {
        customerProxy: this.userName,
        requested: this.lastEdit.time,
        purposes: purposesToSave,
        language: currentLanguage(),
      };
    },
  },
  watch: {
    isDrawerOpen: "onIsDrawerOpenChange",
  },
};
</script>
<style lang="scss" scoped>
@import "@teliads/components/foundations/spacing/variables";

.consent-drawer {
  &__drawer-paragraph {
    margin: $telia-spacing-20 0;
  }

  &__paragraph-wrapper {
    margin: $telia-spacing-20 0;
  }

  &__purpose-toggle {
    margin: $telia-spacing-32 0;
  }

  &__latest-update-skeleton {
    width: 43.5rem;
    height: 3.8rem;
    margin-top: 2.5rem;
  }

  &__info-text-skeleton {
    width: 43.5rem;
    height: 8.5rem;
    margin-top: 3rem;
  }

  &__read-more-skeleton {
    width: 43.5rem;
    height: 60px;
    margin-top: 3rem;
    margin-bottom: 4.5rem;
  }

  &__purpose-skeleton {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 3rem;
  }

  &__toggle-skeleton {
    width: 4.4rem;
    height: 2.5rem;
    margin-right: 1rem;
  }

  &__description-skeleton {
    width: 37rem;
    height: 6rem;
  }
}
</style>
