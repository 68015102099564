<template>
  <b2x-drawer
    t-id="telia-at-work-drawer"
    :heading="drawerHeader"
    position="right"
    :is-open="isOpen"
    @drawerClose="closeDrawer"
  >
    <component
      v-if="isOpen"
      :is="getComponent"
      :activeOrgs="activeOrgs"
      :inActiveOrgs="inActiveOrgs"
      :scopeId="scopeId"
      @closeDrawer="closeDrawer"
      @successfullyUpdatedOrganizations="() => (successfullyUpdatedOrganizations = true)"
    />
  </b2x-drawer>
</template>

<script setup lang="ts">
import { computed, onBeforeMount, onBeforeUnmount, ref } from "vue";

import { translateMixin } from "../../locale";

import TeliaAtWorkActivate from "./telia-at-work-activate.vue";
import TeliaAtWorkDeactivate from "./telia-at-work-deactivate.vue";

import { SettingsDataResponse } from "../../services/corpCompanyConfiguration";

type Action = "activate" | "deactivate";

const props = defineProps({
  isOpen: {
    type: Boolean,
    default: false,
    required: true,
  },
  action: {
    type: String as () => Action,
    required: true,
  },
  selectedOrgs: {
    type: Array as () => SettingsDataResponse,
    required: true,
  },
  scopeId: {
    type: String,
    required: true,
  },
});

const emit = defineEmits<{ (e: "drawerClose", successfullyUpdatedOrganizations: boolean): void }>();

const t = translateMixin.methods.t;
const successfullyUpdatedOrganizations = ref(false);

const drawerHeader = computed(() => {
  if (successfullyUpdatedOrganizations.value) {
    return props.action === "activate"
      ? t("mybusiness.company_settings.telia_at_work.drawer.activate.succesful_activation_header")
      : t(
          "mybusiness.company_settings.telia_at_work.drawer.deactivate.succesful_deactivation_header"
        );
  }

  return props.action === "activate"
    ? t("mybusiness.company_settings.telia_at_work.drawer.activate.header")
    : t("mybusiness.company_settings.telia_at_work.drawer.deactivate.header");
});

const activeOrgs = computed(() => {
  return props.selectedOrgs.filter((org) => org.active);
});

const inActiveOrgs = computed(() => {
  return props.selectedOrgs.filter((org) => !org.active);
});

const getComponent = computed(() => {
  return props.action === "activate" ? TeliaAtWorkActivate : TeliaAtWorkDeactivate;
});

const closeDrawer = () => {
  emit("drawerClose", successfullyUpdatedOrganizations.value);
  successfullyUpdatedOrganizations.value = false;
};
</script>

<style scoped></style>
